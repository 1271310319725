<template>
  <b-card class="backoffice-card">
    <div>
      <!-- Section presentation and create space button -->
      <b-row class="mx-50 flex-wrap flex-lg-nowrap mb-2">
        <b-col cols="12" lg="12" :style="bannerStyle" class="pb-2">
          <h2 class="text-center text-white mt-xl-5">
            {{ $t("my-nectios.explore.title") }}
          </h2>
          <div class="mx-5 px-25 mb-3">
            <h4 class="text-center text-white mx-xl-5 px-lg-5">
              {{ $t("my-nectios.explore.subtitle") }}
            </h4>
          </div>
        </b-col>
      </b-row>
      <!-- Explore Spaces section -->
      <b-card>
        <div class="d-flex flex-wrap justify-content-between mt-2">
          <div class="d-flex flex-wrap">
            <h4>{{ $t("my-nectios.explore.spaces") }}</h4>
            <b-badge v-if="total > 0" variant="primary" class="align-self-start ml-50">
              {{ total }}
            </b-badge>
          </div>
          <!-- Search -->
          <b-form-input
            v-model="searchInput"
            type="text"
            class="d-lg-none my-1 mb-lg-3"
            :placeholder="$t('form-create-item.search-input')"
            @input="search"
          />
          <b-form-input
            v-model="searchInput"
            type="text"
            class="d-none d-lg-block mx-auto mb-1 mb-lg-3"
            style="width: 600px"
            :placeholder="$t('form-create-item.search-input')"
            @input="search"
          />
          <!-- Space type filter -->
          <v-select
            id="type"
            v-model="createForm.type"
            class="mb-1 text-capitalize d-lg-none w-100"
            :options="typesSelect"
            :get-option-label="(option) => option.name"
            :searchable="false"
            :placeholder="$t('form.select-placeholder')"
            label="name"
            style="width: 200px"
            :clearable="false"
            @input="fetchData(1)"
          />
          <div class="d-flex flex-wrap">
            <v-select
              id="type"
              v-model="createForm.type"
              class="mb-1 text-capitalize d-none d-lg-block"
              :options="typesSelect"
              :get-option-label="(option) => option.name"
              :searchable="false"
              :placeholder="$t('form.select-placeholder')"
              label="name"
              style="width: 200px"
              :clearable="false"
              @input="fetchData(1)"
            />
          </div>
        </div>
        <!-- loading -->
        <div v-if="isLoading" class="mb-5">
          <b-spinner
            type="grow"
            small
            variant="primary"
            class="mx-auto mt-3 d-block"
          />
        </div>
        <!-- Spaces cards grid -->
        <div v-else>
          <div v-if="communities && communities.length > 0" class="mt-2 d-flex flex-wrap justify-content-around w-100">
            <div v-for="(collective, index) in communities"
              :key="index"
              class="x-scroll-element mx-1 mb-2">
              <b-link
                class="text-dark"
                target="_blank"
                :href="getCollectiveUrl(collective)"
              >
                <b-card class="small-card">
                  <card-thumbnail
                    :src="thumbnailSrc(collective)"
                    :placeholder="placeholder"
                  />
                  <h5 class="mt-1">
                    {{ titleShort(collective) }}
                  </h5>
                  <p class="m-0 small-card-headline text-secondary html-text-ellipsis-2">
                    {{ headline(collective) }}
                  </p>
                  <small v-if="collective && collective.mainType">
                    <b-badge pill
                      :variant="getColor(collective.mainType)"
                      class="ml-25">
                      {{ collective.mainType }}
                    </b-badge>
                  </small>
                </b-card>
              </b-link>
            </div>
          </div>
          <!-- Container without data: Placeholder -->
          <b-row v-else class="horizontal-placeholder">
            <b-col v-if="placeholder" cols="12">
              <img :src="placeholder" />
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{
                  $t("available.message", {
                    itemName: $t("my-nectios.communities"),
                  })
                }}
              </p>
            </b-col>
          </b-row>
          <div v-if="isLoadingNextPage" class="mb-5">
            <b-spinner
              type="grow"
              small
              variant="primary"
              class="mx-auto mt-3 d-block"
            />
          </div>
          <b-button
            v-if="hasMore"
            class="center-x my-3"
            variant="outline-primary"
            @click="getMoreCommunities"
          >
            {{ $t("action.load-more") }}
          </b-button>
        </div>
      </b-card>
      <error-modal
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        :title="$t('appsumo.errors-modal.title')"
        :subtitle="$t('appsumo.errors-modal.subtitle')"
      />
    </div>
  </b-card>
</template>

<script>
import MainContainer from "@/my-nectios/components/MainContainer.vue";
import ListContainer from "@core/constants/ListContainer";
import CommunitiesPlaceholder from "@/assets/images/placeholders/light/communities.svg";
import Service from "@/config/service-identifiers";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import CardThumbnail from "@core/components/card-thumbnail/CardThumbnail.vue";
import vSelect from "vue-select";
import { MainType } from "@copernicsw/community-common";
import Banner from "@/assets/images/placeholders/light/Header_My_Left.svg";
import callToAction from "@/assets/images/placeholders/light/Header_My_Right.svg";
import ErrorModal from "@core/components/modal/ErrorModal.vue";

export default {
  name: "SpacesList",
  components: {
    CardThumbnail,
    ErrorModal,
    vSelect,
    MainContainer,
  },
  data() {
    return {
      createForm: {
        type: {},
        name: "",
      },
      isContactModalVisible: false,
      isLoading: false,
      isLoadingNextPage: false,
      perPage: 20,
      lastLoadedPage: 1,
      communities: [],
      currentPage: 1,
      total: null,
      searchInput: "",
      page: 1,
      timeoutID: null,
      isSmall: false,
      isWrongPassword: false,
      generalError: false,
      invalidCredentials: false,
    };
  },
  computed: {
    callToActionImage() {
      return callToAction;
    },
    typesSelect() {
      return [
        { 
          type: "all", 
          name: this.$t("display-type-options.show-all") 
        },
        {
          type: "Community",
          name: this.$t("my-nectios.welcome.types-options.community"),
        },
        {
          type: "Event",
          name: this.$t("my-nectios.welcome.types-options.event"),
        },
        { 
          type: "Workspace", 
          name: "Workspace" 
        },
        // { type: 'Course', name: this.$t('my-nectios.welcome.types-options.course') },
        // { type: 'Project', name: this.$t('my-nectios.welcome.types-options.project') },
      ];
    },
    gridContainer() {
      return ListContainer.Grid;
    },
    placeholder() {
      return CommunitiesPlaceholder;
    },
    currentUser() {
      return this.$store.getters.loggedUser.key;
    },
    banner() {
      return Banner;
    },
    bannerStyle() {
      return {
        "background-image": `url(${this.banner})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        ...(this.isSmall
          ? { "background-position": "center" }
          : { "background-position": "center" }),
        "border-radius": "1.2rem",
        "margin-right": "10px",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
      };
    },
    callStyle() {
      return {
        "background-image": `url(${this.callToActionImage})`,
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "border-radius": "1.2rem",
        display: "flex",
        "flex-direction": "column",
        "justify-content": "center",
      };
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
  },
  watch: {
    async currentUser() {
      if (this.searchInput) {
        return;
      }
      await this.fetchData(this.page);
    },
    loggedUser(value) {
      return value;
    },
    communities(value) {
      return value;
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.createForm.type = this.typesSelect[0];
    await this.fetchData(this.page);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setLocale(language) {
      this.$store.dispatch("setLocale", language);
    },
    handleResize() {
      this.isSmall = window.innerWidth < 400;
    },
    titleShort(collective) {
      const title = translateTranslationTable(
        this.$store.state.locale.currentLocale,
        collective?.name
      );
      if (title.length < 19) {
        return title;
      }
      return this.trimString(title);
    },
    headline(collective) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        collective?.headline
      );
    },
    getCollectiveUrl(collective) {
      return process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        "{subdomain}",
        collective?.subdomain || "app"
      ).replace("{slug}", collective?.slug);
    },
    trimString(string) {
      const trimmedString = string.substr(0, 19);
      return `${trimmedString}...`;
    },
    async fetchData(page = 1) {
      this.isLoading = true;
      if (this.page !== page) {
        this.isLoadingNextPage = true;
      }
      this.page = page;
      const response = await this.$store.$service[Service.BackendClient].get(
        "communities",
        {
          params: {
            page: this.page,
            userKey: this.currentUser,
            ...(this.createForm.type.type !== "all"
              ? { mainTypeID: MainType[this.createForm.type.type] }
              : ""),
          },
        }
      );
      this.communities =
        this.page === 1
          ? response.data.data.map((item) => item)
          : [...this.communities, ...response.data.data.map((item) => item)];
      this.hasMore =
        response.data.meta.current_page < response.data.meta.last_page;
      this.total = response.data.meta.total;
      if (this.loggedUser?.language) {
        this.setLocale(this.loggedUser?.language);
      }
      this.isLoading = false;
      this.isLoadingNextPage = false;
    },
    translateTranslationTable,
    thumbnailSrc(collective) {
      return collective?.logoURL ?? collective?.bgURL ?? CommunitiesPlaceholder;
    },
    async getMoreCommunities() {
      this.lastLoadedPage += 1;
      return this.fetchData(this.lastLoadedPage);
    },
    async search() {
      let response = "";
      if (this.searchInput.length === 0) {
        this.communities.length = 0;
        await this.fetchData(this.page);
      } else {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
        }
        this.timeoutID = setTimeout(async () => {
          response = await this.$store.$service[Service.BackendClient].get(
            "communities",
            {
              params: {
                searchString: this.searchInput.toLowerCase().trim(),
                userKey: this.currentUser,
                mainTypeID: MainType[this.createForm.type.type],
              },
            }
          );
          this.timeoutID = null;
          this.communities.length = 0;
          this.page = 1;
          this.communities = [...response.data.data.map((item) => item)];
          this.hasMore =
            response.data.meta.current_page < response.data.meta.last_page;
          this.isLoading = false;
        }, 500);
      }
    },
    getColor(type) {
      const types = {
        Event: "success",
        Community: "primary",
        Project: "info",
        Challenge: "warning",
        Workspace: "dark",
        Service: "light",
        Subcommunity: "secondary",
      };
      return types[type] || "secondary";
    },
    handleCreateMax() {
      this.$bvModal.show("error-modal");
    },
  },
};
</script>
<style scoped>
.button-text {
  font-size: 18px !important;
}
</style>

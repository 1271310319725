<template>
  <b-modal
    id="error-modal"
    :visible="visible"
    hide-header
    hide-footer
    ok-only
    :ok-title="$t('form.actions.ok')"
    centered
    @change="$emit('change', false)"
  >
    <div class="float-right pointer" @click="closeModal">
      <feather-icon icon="XIcon" size="20" />
    </div>
    <div class="mt-3 mb-2 d-flex flex-column justify-content-center">
      <span
        class="b-avatar shadow mb-2 rounded-circle d-block mx-auto"
        :class="`badge-${variant} bg-light-${variant}`"
        style="width: 50px; height: 50px"
      >
        <b-icon
          :icon="icon"
          font-scale="2"
          class="mt-75"
          :class="`text-${variant}`"
        />
      </span>
      <h4 class="text-center font-weight-normal">
        {{ title }}
      </h4>
      <h5 class="text-center" :class="`text-${variant}`">
        {{ subtitle }}
      </h5>
      <b-button
        href="https://appsumo.com/products/nectios/"
        class="mt-1 mx-auto"
        variant="primary"
        target="_blank"
      >
        {{ $t("appsumo.errors-modal.title") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "LoadingModal",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "shield-exclamation",
    },
    subtitle: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "danger",
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("error-modal");
    },
  },
};
</script>
